import { axiosInstance } from "../../axios";
const GETSearchBallotAction = `/api/BallotAction/Search`;
const POSTOpenBallot = `/api/BallotAction/OpenBallot`;
const POSTRunBallot = `/api/BallotAction/RunBallot`;
const POSTApproveBallot = `/api/BallotAction/ApproveBallot`;

const searchBallotAction = async function(id) {
  try {
    const response = await axiosInstance.bhAPIInstance.get(`${GETSearchBallotAction}/${id}`);
    let data = parseList(response);
    return data;
  } catch (error) {
    return [];
  }
};

const openBallot = async function(ballot) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTOpenBallot, ballot);
    return response.data;
  } catch (error) {
    return parseError(error);
  }
};

const runBallot = async function(ballot) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTRunBallot, ballot);
    return response.data;
  } catch (error) {
    return parseError(error);
  }
};


const approveBallot = async function(ballot) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTApproveBallot, ballot);
    return response.data;
  } catch (error) {
    return parseError(error);
  }
};

const parseError = error => {
  let stringMessage = error.response.data.Errors.join(" <br/> ");
  error.response.data.Message += "<br/>" + stringMessage;
  return error.response.data;
};

const parseList = response => {
  if (response.status !== 200) throw Error(response.message);
  if (!response.data) return [];
  let list = response.data;
  if (typeof list !== "object") {
    list = [];
  }
  return list;
};

export const ballotActionData = {
  searchBallotAction,
  openBallot,
  runBallot,
  approveBallot
};
